import './App.css';
import Sidebar from './sidebar';
import Profile from './pages/Profile';
import Images from './pages/Images';
import Events from './pages/Events';
import TestGround from './pages/testGround';
import { BrowserRouter as Router,
  Routes,
  Route
 } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className='bucket'>
        <Sidebar className="sidebar">
          </Sidebar>
      
      <div className="content" >
        <Routes>
          <Route exact path="/" element={<Profile />}></Route>
          <Route exact path="/library" element={<Images />}></Route>
          <Route exact path="/events" element={<Events />}></Route>
          <Route exact path="/test" element={<TestGround />}></Route>
        </Routes>
      </div>
      </div>
    </Router>
  );
}

export default App;

import React from "react";
import Saheb from "./IMG_6163.jpeg";
import Alert from 'react-bootstrap/Alert';


export default class Profile extends React.Component {
    render() {
      return (
        <React.Fragment>
           
        <Alert key='info' variant='info'>
        Hi My name is Saheb Batra. More Content to follow soon
        </Alert>
                    <p></p>
        <img src={Saheb}/>
        </React.Fragment>
       
  );
      }
}